
/*--------- FONTS ----------*/
@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
@import url('https://fonts.cdnfonts.com/css/satoshi');


/*--------- BODY ----------*/

html, body {
  height: 100%;
  margin:0;
  scroll-behavior: smooth;
}

body {
  background-color: rgb(0, 0, 0);
}

h1, h2, h3, p, ul, li {
  transition: all 0.1s;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-shrink: 0;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.55);
}

/*--------- WRAPPER ----------*/

.hwrapper{
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100vh;
}

.title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 900;
  width: 100%;
  font-size: 9.4vw;
  color: white;
  margin: 0;
  text-align: center;
  margin-top: -2%;
}

/*--------- HEADER ----------*/
.header {
  width: 25%;
  height: 90%;
  margin-top: 10%;
  top: 0;
  position: fixed;
}

.header #boka a {
  color: white;
}

.header ul {
  margin-top: 10%;
  width: 100%;
}

.header ul li {
  font-size: 1vw;
  font-family: 'Satoshi', sans-serif;
}

.header ul li a {
  color: white;
  letter-spacing: 1px;
  text-decoration: none;
  filter: opacity(90%);
  transition: 0.3s all;
  display: inline;
}


.header a:hover {
  color: #20b479;
  text-decoration: none;
  letter-spacing: 10px;
  transition: 0.3s all;
}

.header a:active {
  color: white;
}


.header li{
  margin-bottom: 4px;
  display: block;
  cursor: pointer;
  display: inline;
}
/*
.hlogo {
  height: 200px;
  top: 0;
  padding-top: 20%;
  padding-bottom: 20%;
}
*/
/*--------- CONTENT ----------*/
.headerContent {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 75%;
  margin-left: 25%;
  justify-content: center;
}

/*--------- HOME ----------*/
.home {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;
  height: 100%;
}

.home h1 {
  color: white;
  font-size: 5vw;
  font-family: 'Satoshi', sans-serif;
  font-weight: 400;
  margin: 0;
}

.home h2 {
  color: #20b479;
  font-size: 1vw;
  font-family: 'Satoshi', sans-serif;
  font-weight: 400;
  letter-spacing: 8px;
  margin-top: 0;
}

.home p {
  color: white;
  font-size: 1.2vw;
  font-family: 'Satoshi', sans-serif;
  letter-spacing: 15.5px;
  margin-top: 40%;
}

/*--------- ABOUT ----------*/
.about{
  display:flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
  height: 100%;
  margin-top: 5%;
  text-align: left;
}

.about h1 {
  color: white;
  font-size: 2vw;
  letter-spacing: 1px;
  font-family: 'Satoshi', sans-serif;
  margin: 0;
}

.about h2 {
  color: white;
  font-size: 1.7vw;
  font-family: 'Satoshi', sans-serif;
  letter-spacing: 1px;
  margin-top: 1vw;
  width: 100%;
}

.about p {
  font-family: 'Satoshi', sans-serif;
  color: white;
  font-size: 1.1vw;
  letter-spacing: 0.3px;
  margin: 0;
}

/*--------- FAQ ----------*/
.faq{
  display:flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  margin-top: 5%;
  text-align: left;
}

.faq h1 {
  color: white;
  font-size: 2vw;
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;
  margin: 0;
}

.faq h2 {
  color: white;
  font-size: 1.3vw;
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;
  width: 100%;
  margin: 0;
}

.faq p {
  font-family: 'Satoshi', sans-serif;
  width: 100%;
  font-size: 1.1vw;
  color: white;
  margin-top: 1px;
}

.faq .colored {
  font-family: 'Satoshi', sans-serif;
  font-weight: 900;
}

/*--------- MENU ----------*/
.beer {
  width: 100%; 
  height: 100%;
  align-items: center;
  margin-top: 5%;
}

/*
.beer h1 {
  color: white;
  font-size: 2vw;
  font-family: 'Satoshi', sans-serif;
  margin: 0;
}*/

/*--------- WORK ----------*/
.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 80vh;
}

.work h1 {
  color: white;
  font-size: 3vw;
  font-family: 'Satoshi', sans-serif;
}

.work p {
  font-size: 1.1vw;
  font-family: 'Satoshi', sans-serif;
  color: white;
}

#arbetaForm {
  width: 100%;
  height: 50%;
}

/*--------- BOOKING ----------*/
.bContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2vh;
}

.bContent h1, h2 {
  font-family: 'Satoshi', sans-serif;
  color: white;
  font-size: 2vw;
}

.bContent p {
  font-family: 'Satoshi', sans-serif;
  color: white;
  width: 80%;
  font-size: 1.1vw;
  margin-top: 5%;
  text-align: center;
}

.bContent li {
  margin-bottom: 10px;
}

div .calendar {
  width: 70vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

.confirmBookings{
  width: 90%;
  height: 100%;
  text-align: center;
  overflow: auto;
  justify-content: center;
}

.bookingsTable {
  flex-wrap: wrap;
  font-family: 'Satoshi', sans-serif;
  border-collapse: collapse;
  color: white;
  text-align: left;
  vertical-align: middle;
  opacity: 0.9;
  margin: 0 auto;
  margin-bottom: 3vh;
  height: 20%;
  width: 100%;
}

.bookingsTable td, th {
  font-family: 'Satoshi', sans-serif;
  border-bottom: 1px solid white;
  padding: 8px;
  max-width: 10vw;
  overflow: auto;
}

/* Hide scrollbar on checkbox */
.bookingsTable :nth-child(6) {
  overflow: hidden;
}

.bookingsTable thead th {
  width: 25%;
}

.bookingsTable h3 {
  font-family: 'Satoshi', sans-serif;
  color: white;
}

.BoxInfo {
  display: flex;
  flex-direction: row;
  margin: 20px;
  align-items: center;
}

.BoxInfo p {
  width: 50px;
  height: 40px;
  margin: 0 0 0 20px; 
  border-radius: 2px;
  color: white;
}

/*.BoxInfo h3{
  color: black;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1vw;
  margin-left: 7px;
  letter-spacing: 0.5px;
}

.BookedBoxPub {
  background-color: #1a7525c8;
  opacity: 0.8;
  border: 1px solid black;
}

.BookedBoxIndivid {
  background-color: rgb(0, 255, 89);
  opacity: 0.8;
  border: 1px solid black;
}

.Free {
  background-color: #d8dad7;
  border: 1px solid black;
}*/

/*--------- CONTACT ----------*/
.contact {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
  height: 100%;
}

.contact h1 {
  color: white;
  font-size: 2vw;
  font-family: 'Regular';
}

.contact h3, a{
  color: white;
  font-size: 1.2vw;
  font-family: 'Regular';
  margin: 0;
  transition: 0.3s all;
}

.contact a:hover {
  color: #20b479;
  letter-spacing: 2px;
  transition: 0.3s all;
}

.fIcon {
  width: 6vw;
  height: 6vw;
  margin-right: 5vw;
  transition: 0.2s all;
}

.iIcon {
  width: 6vw;
  height: 6vw;
  transition: 0.2s all;
}

.fIcon:hover, .iIcon:hover {
  color: #20b479;
  height: 6.5vw;
  width: 6.5vw;
  transition: 0.2s all;
}

/*--------- FOOTER ----------*/
.fotnot {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer {
  font-weight: lighter;
}

.footer h4 {
  color:rgb(51, 69, 71);
  font-family: 'Regular';
  font-size: 10px;
  float: left;
}

.loginSymbol{
  float: right;
  cursor: pointer;
}

/*--------- POP-UPS ----------*/
.popupButton {
  background: white;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 5px;
  padding: 5px;
  font-size: 16px;
  font-weight: 100;
}

.popupButton:hover{
  background: #1f3d0c;
  transition: 0.3s all;
}

input[type="button"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

/*--------- TO BE CHANGED ----------*/
/*
.pubInfo ul {
  display: flex;
  flex-direction: column;
  list-style-type: none; 
  }

.pubInfo li {
  padding: 2%;
  width: 20vw;  
}

.clicker {
  --fill-color: #b15000de;
  display: block;
  font: 600 5.5vw Raleway, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-text-stroke: var(--fill-color);
  text-align: left;
  background: linear-gradient(var(--fill-color) 0 100%) left / 0 no-repeat;
  color: black;
  transition: 0.3s linear;
  cursor: pointer;
  border: 1px solid black;  
  width: 100%;
}

.clicker:hover {
  background-size: 60vw;
}

.hiddendiv{
  align-content: center;
  max-width: 55vw;
  background-color:#EBD2CD;
  padding: 3vh 0;
  padding-left: 1%;
  margin: 10px;
}

.hiddendiv p {
  font-size: 2vh;
  color: #112221;
  width: 100%;
}


.hiddendiv2 {
  width: 55vw;
  background-color:#EBD2CD;
  padding: 1.5vh 0;
  text-align: center;
  margin: 10px;
}

#styret {
  width: 50vw;
  align-self: center;
  border: 5px solid #125446;
}

#styretInfo {
  font-size: 2vh;
  color: #112221;
}

.hiddendiv2 h1 {
  color: #112221
}

.hiddendiv2 a {
  color: #125446;
  text-decoration: none;
}

.hiddendiv2 h3 {
  padding: 0.5vh;
  font-size: 2vh;
  text-decoration: underline;
}

.hiddendiv2 p {
  font-size: 1.8vh;
  margin-top: -2.5vh;
}

.hiddendiv3 {
  align-content: center;
  max-width: 96%;
  background-color:#DB8C7B;
  padding: 1%;
  margin: 10px;
}
*/

/*--------- MOBILE ----------*/

@media only screen and (max-width: 1000px) {
  /*--------- BODY ----------*/
  body {
    margin: 0;
    padding: 0;
    max-width: 100vw;
  }

  .title {
    margin-top: 2.5%;
  }

  .bg {
    height: 100%;
    width: 300%;
    margin-left: -100%;
  }
  
  /*--------- HEADER ----------*/
  .header {
    width: 100%;
    height: 8%;
    margin-top: -10%;
    margin-left: 1.5%;
    margin-bottom: 0;
    position: relative;
  }

  .header ul, li {
    display: inline-flex;
    width: 100%;
    padding: 0;
  }

  .header ul li a {
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 5%;
    font-size: 2vw;
  }

  /*--------- CONTENT ----------*/
  .headerContent {
    width: 100%;
    margin-top: 5%;
    margin-left: 1.5%;
    z-index: 1;
  }

  /*--------- HOME ----------*/
  .home {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
  }

  .home h1 {
    font-size: 10vw;
  }

  .home h2 {
    font-size: 2vw;
    margin-bottom: 5%;
  }

  .home p {
    margin-top: 80%;
    font-size: 2.2vw;
    letter-spacing: 7px;
  }

  /*--------- ABOUT ----------*/
  .about {
    width: 90%;
    margin-top: 5%;
  }

  .about h1 {
    font-size: 4.2vw;
    margin-bottom: 5%;
  }

  .about h2{
    font-size: 3.2vw;
    margin-bottom: 1%;
  }

  .about p {
    font-size: 2.2vw;
    margin-bottom: 3%;
  }

  /*--------- FAQ ----------*/
  .faq {
    width: 90%;
    margin-top: 5%;
  }

  .faq h1 {
    font-size: 4.2vw;
    margin-bottom: 2%;
  }

  .faq h2{
    font-size: 3.2vw;
    margin-bottom: 0%;
  }

  .faq p {
    font-size: 2.2vw;
    margin-bottom: 1%;
  }

  /*--------- BEER ----------*/
  .beer {
    width: 90%;
    margin-top: 5%;
  }

  /*.beer h1 {
    font-size: 5vw;
    margin-bottom: 5%;
  }*/

  /*--------- WORK ----------*/
  .work {
    width: 90%;
    margin-top: 5%;
  }

  .work h1 {
    font-size: 5vw;
    margin-bottom: 5%;
  }

  .work p {
    font-size: 2.2vw;
    margin-bottom: 3%;
  }

  #arbetaForm {
    width: 90%;
    margin-top: 5%;
    height: 75%;
  }

  /*--------- BOOKINGS ----------*/
  .bContent h1 {
    font-size: 5vw;
    margin-bottom: 5%;
  }

  .bContent p {
    font-size: 2.3vw;
    margin-bottom: 3%;
  }

  .bookingsTable td, th{
    font-family: Mulish;
    overflow: hidden;
    padding: 1vw;
    text-align: center;
    font-size: 2.3vw;
  }

  .bookingsTable td:nth-child(n+2):nth-child(-n+6), 
                th:nth-child(n+2):nth-child(-n+6){
    display:none;
  }

  tbody button {
    max-width:80%;
    overflow: hidden;
    font-size: 2vw;
  }

  div .calendar{
    padding-bottom: 0vh;
  }

  /*--------- CONTACT ----------*/
  .contact {
    width: 90%;
    margin-top: 5%;
  }

  .contact h1 {
    font-size: 5vw;
    margin-bottom: 5%;
  }

  .contact h3, a{
    font-size: 3.2vw;
    margin-bottom: 1%;
  }

  .fIcon {
    width: 25vw;
    height: 25vw;
    transition: 0.2s all;
  }

  .fIcon:hover {
    width: 27vw;
    height: 27vw;
    transition: 0.2s all;
  }

  .iIcon{
    width: 25vw;
    height: 25vw;
    transition: 0.2s all;
  }

  .iIcon:hover {
    width: 27vw;
    height: 27vw;
    transition: 0.2s all;
  }

  /*--------- FOOTER ----------*/
  .footer {
    width: 100%;
    margin-top: 10%;
  }

  .footer h4 {
    font-size: 1vh;
    margin-left: -5%;
  }

  .loginSymbol{
    cursor: pointer;
    margin-left: 80%;
  }
}
