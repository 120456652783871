/*---------- FONT -----------*/
@font-face {
  src: url(../../public/fonts/Satoshi-Regular.ttf);
  font-family: 'Regular';
}


/* Custom CSS file for React Big Calendar package */
.dateIsBooked {
    background: rgb(45, 171, 41) !important;
    color: white !important;
}

.react-calendar {
    max-width: 79%;
    background: white;
    font-family: 'Regular';
    line-height: 1.125em;
    text-transform: uppercase;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;

    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;

  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-family:  'Regular';
    color: black;
    border: 1.2px solid black;
    text-transform: uppercase;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #20b479;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }

  .react-calendar__month-view__days__day--weekend {
    color: #26ff00;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    color: black;
    border: .5px black solid;
  }
  .react-calendar__tile:disabled {
    background-color: #ffffff;
    color: black;
    opacity: 0.3;
    text-decoration: line-through;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #20b479;
  }
  .react-calendar__tile--now {
    /*background: #ffff76;*/
    border: #00833b 2px solid !important;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    /*background: #ffffa9;*/
  }
  .react-calendar__tile--hasActive {
    /*background: #76baff;*/
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    /*background: #a9d4ff;*/
  }
  .react-calendar__tile--active {
    /*background: #006edc;
    color: white;*/
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    /*background: #1087ff;*/
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  @media only screen and (max-width: 1000px) {
  
    .react-calendar{
      max-width:80%;
    }
  
  }

  