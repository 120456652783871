@font-face {
  src: url(../../public/fonts/Satoshi-Regular.ttf);
  font-family: 'Regular'
}

/* Common CSS-file for all popups */
.popupHeader {
    color:black;
}

.popup-content {
    margin: auto;
    background:white;
    font-family: 'Regular';
    width: 40%;
    text-align: center;
  }
  .popup-arrow {
    color: black;
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

  .form {
    background: #0e101c;
    max-width: 200px;
    margin: 0 auto;
  }
  
  p {
    color: #bf1650;
  }
  
  p::before {
    display: inline;
  }
  
  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: black;
    font-size: 14px;
    font-weight: 200;
  }
  
  input[type="submit"], button.popupButton {
    background: white;
    color: black;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    border: 1px solid black;
  }
  
  input[type="submit"]:hover:enabled, .popupButton:hover:enabled {
    background: rgb(68, 255, 0);
    color: black;
    transition: 0.3s all;
  }
  
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }

  input[type="checkbox"]:disabled {
    opacity: 1;
    font-size: 3vw;
  }
  
  input:disabled {
    opacity: 0.4;
  }

  
  input[type="button"]:hover {
    transition: 0.3s all;
  }
  
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }


  @media only screen and (max-width: 1000px) {
  
    .popup-content {
      width: 80%;
      max-height: 90vh;
      overflow-y: auto;
      padding-bottom: 20vh;
    }
  }

