/*.menu {
  color:white;
  font-family: 'Satoshi';
}

.menu ul {
  margin: 0;
  padding: 0;
}

.menu p {
  color: white;
}

.menuItem {
  margin: 0;
}



.menuImageContainer {
  display: flex;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  margin: auto;
}

.menu {
  color:white;
  font-family: 'Satoshi';
}

.menu ul {
  margin: 0;
  padding: 0;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.menuItem h3, p {
  margin: 0;
}


.menuImageContainer {
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0;
}

.menuImageContainer img {
  padding: auto;
  height: 100%;
}*/

.menu {
  color:white;
  font-family: 'Satoshi';
  max-width: 600px;
}

.menu h1 {
  font-size: 3em;
  margin-top: 0;
}
.menu h2 {
  font-size: 2em;
}

.menuList {
  margin: 0;
  padding: 0;
}
.menuListItem {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
}

.menuListItem div {
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
}

.menuListItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.menuListItem h3 {
  width: 100%;
  margin: 0;
  font-size: 1em;
}

.menuListItem p {
  width: 64px;
  margin: 0;
  color: white;
  font-size: 1em;
}

.menuImageContainer img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 25vh;
}

.spinner_aj0A{
  transform-origin:center;
  fill: white;
  animation:spinner_KYSC .75s infinite linear
}
@keyframes spinner_KYSC{100%{transform:rotate(360deg)}}